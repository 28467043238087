export const getCSSVars = name => {
  return getComputedStyle(document.documentElement).getPropertyValue(name)
}

export const isElementInViewport = el => {
  // Special bonus for those using jQuery
  if (typeof jQuery === 'function' && el instanceof jQuery) {
    el = el[0]
  }

  var rect = el.getBoundingClientRect()
  return (
    rect.top >=
      document.getElementById('header').offsetHeight - rect.height / 2 &&
    rect.bottom <=
      (window.innerHeight ||
        document.documentElement.clientHeight) /* or $(window).height() */
  )
}

export const decimalCount = num => {
  // Convert to String
  const numStr = String(num)
  // String Contains Decimal
  if (numStr.includes('.')) {
    return numStr.split('.')[1].length
  }
  // String Does Not Contain Decimal
  return 0
}

export const GetURLParameter = sParam => {
  let sPageURL = window.location.search.substring(1)
  let sURLVariables = sPageURL.split('&')
  for (let i = 0; i < sURLVariables.length; i++) {
    let sParameterName = sURLVariables[i].split('=')
    if (sParameterName[0] === sParam) {
      return sParameterName[1]
    }
  }
}

export const debounce = (method, delay) => {
  clearTimeout(method._tId)
  method._tId = setTimeout(function () {
    method()
  }, delay)
}

export const killScrollTrigger = tl => {
  if (tl !== undefined && tl.scrollTrigger) {
    tl.scrollTrigger.kill(true)
  }
}

export const refreshScrollTrigger = ScrollTrigger => {
  if (ScrollTrigger !== undefined) {
    ScrollTrigger.sort((a, b) => a.start - b.start)
    ScrollTrigger.refresh()
  }
}
