import { getCSSVars } from '../util/functional'

export default {
  html: $('html'),
  body: $('body'),
  htmlFixedState: false,
  scrollTopState: 0,
  breakpoints: {
    sm: parseInt(getCSSVars('--bp-sm')) - 1,
    md: parseInt(getCSSVars('--bp-md')) - 1,
    lg: parseInt(getCSSVars('--bp-lg')) - 1,
    xl: parseInt(getCSSVars('--bp-xl')) - 1,
    xxl: parseInt(getCSSVars('--bp-xxl')) - 1,
  },
  utmMetas: [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_adset',
    'utm_adgroup',
    'utm_content',
    'utm_keyword',
    'utm_gclid',
    'utm_fbcid',
    'utm_term',
  ], //?utm_source=utm_source-test&utm_campaign=utm_campaign-test&utm_medium=utm_medium_test&utm_adset=utm_adset-test&utm_adgroup=utm_adgroup-test&utm_content=utm_content-test&utm_keyword=utm_keyword-test&utm_gclid=utm_gclid-test&utm_fbcid=utm_fbcid-test&utm_term=utm_term-test
  slickDefault: {
    nextArrow:
      '<button type="button" class="slick-next">' +
      '<svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M1 1L14.5 15L1 27" stroke="currentColor" stroke-width="2"/>' +
      '</svg></button>',
    prevArrow:
      '<button type="button" class="slick-prev">' +
      '<svg width="17" height="28" viewBox="0 0 17 28" fill="none" xmlns="http://www.w3.org/2000/svg">' +
      '<path d="M15.5 27L2 13L15.5 1" stroke="currentColor" stroke-width="2"/>' +
      '</svg></button>',
    rows: 0, // Prevent generating extra markup
  },
  htmlFixed() {
    const scrollTop = $(document).scrollTop()
    this.html.toggleClass('is-fixed')
    this.htmlFixedState = !this.htmlFixedState
    if (this.htmlFixedState) {
      this.scrollTopState = scrollTop
      this.body.css({
        top: -this.scrollTopState,
      })
    } else {
      this.body.css({
        top: '',
      })
      $('html, body').animate({ scrollTop: this.scrollTopState }, 0)
    }
  },
}
