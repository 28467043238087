import Splide from '@splidejs/splide'
import { isElementInViewport } from '../../assets/scripts/util/functional'
import Global from '../../assets/scripts/inc/global'
const { breakpoints } = Global

const Component = {
  init() {
    this.servicesSlider()
  },
  servicesSlider() {
    const servicesSlider = $('.splide.js-services-slider')
    if (servicesSlider.length) {
      servicesSlider.each(function () {
        // const $this = $(this)
        // const slideCount = $this.find('.splide__slide').length

        new Splide(this, {
          type: 'loop',
          drag: 'free',
          // destroy: slideCount <= 6,
          destroy: true,
          focus: 0,
          arrows: false,
          pagination: false,
          autoWidth: true,
          perPage: 1,
          gap: '10px',
          padding: '10px',
          breakpoints: {
            // [breakpoints.xxl - 74]: {
            //   destroy: false,
            // },
            [breakpoints.lg]: {
              destroy: false,
            },
          },
        }).mount()
      })

      const serviceItems = $('.js-service', servicesSlider)

      const setItemsPosition = () => {
        const centerWindow = $(window).width() / 2
        serviceItems.each(function () {
          const $this = $(this)
          const centerItem = $this.outerWidth() / 2
          const itemOffset = $(this).offset().left

          const position = centerWindow - itemOffset - centerItem

          if (!$this.hasClass('is-animated')) {
            $this.css({
              '-webkit-transform': 'translateX(' + position + 'px)',
              '-moz-transform': 'translateX(' + position + 'px)',
              '-ms-transform': 'translateX(' + position + 'px)',
              '-o-transform': 'translateX(' + position + 'px)',
              transform: 'translateX(' + position + 'px)',
              opacity: 0,
            })
          }
        })
      }

      const animateItems = () => {
        serviceItems.each(function () {
          const $item = $(this)
          if (isElementInViewport(this) && !$item.hasClass('is-animated')) {
            $item.addClass('is-animated')
            const position = 0
            $item.css({
              '-webkit-transform': 'translateX(' + position + 'px)',
              '-moz-transform': 'translateX(' + position + 'px)',
              '-ms-transform': 'translateX(' + position + 'px)',
              '-o-transform': 'translateX(' + position + 'px)',
              transform: 'translateX(' + position + 'px)',
              opacity: 1,
            })
          } else if (
            !isElementInViewport(this) &&
            $item.hasClass('is-animated')
          ) {
            $item.removeClass('is-animated')
            setItemsPosition()
          }
        })
      }

      setItemsPosition()
      animateItems()

      $(window).on('resize', function () {
        setItemsPosition()
        animateItems()
      })

      $(window).on('scroll', function () {
        animateItems()
      })
    }
  },
}

$(() => {
  Component.init()
})
